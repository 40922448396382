<template>
  <div class="container-label">
    <div v-if="!loading" class="page">
      <div class="px-4 py-4" style="position: relative;">
        <div class="d-flex justify-content-between mb-4">
          <div class="w-25 text-left mb-2">
            <div class="logo">
              <img
                :src="$store.state.app?.logo"
                class="mr-2"
                alt="Clean UI"
              />
            </div>
          </div>
          <div class="w-50 text-center text-dark mb-2 text-title"> LAPORAN PEMBAYARAN </div>
          <div class="w-25 text-right text-nomor"> No Pembayaran: <div class="text-right text-nomor-2">{{ receiptDetail && receiptDetail.payment_number }}</div> </div>
        </div>

        <div class="mb-0">
          <div class="text-left mb-2 w-100">
            <div class="d-flex">
              <div class="w-25 desc-text-1">Telah Diterima Dari</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="w-75 desc-text-2">{{ getFullName }}</div>
            </div>
            <div class="d-flex">
              <div class="w-25 desc-text-1">Tanggal Bayar</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="w-75 desc-text-2">{{ getOrderDate }}</div>
            </div>
            <div class="d-flex">
              <div class="w-25 desc-text-1">Uang Sebesar</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="w-75 desc-text-2">Rp. {{ toCurrency(receiptDetail && receiptDetail.total_amount) }}</div>
            </div>
          </div>
        </div>
        <div class="text-left w-100 desc-text-1 mt-4 mb-3">Untuk Pembayaran Tagihan :</div>

        <div class="d-flex align-items-center">
          <table style="width:100%;">
            <tr class="text-left title-text" style="border-bottom: 2px solid #000000; border-top: 2px solid #000000; line-height: 50px">
              <th> NO ORDER </th>
              <th> TANGGAL ORDER </th>
              <th> OUTSTANDING </th>
              <th> TERBAYAR </th>
            </tr>
            <tr class="text-left tr-product-list" style=" border-bottom: 1px solid #F4F4F4;" v-for="(item, index) in orderList" :key="index">
              <td class="desc-text-3" style="vertical-align: top;">{{ item.orderNumber }}</td>
              <td class="desc-text-3" style="vertical-align: top;">{{ getOrderDateWithParam(item.orderDate) }}</td>
              <td class="desc-text-3" style="vertical-align: top;"> Rp {{ toCurrency(item.outstandingAmount) }} </td>
              <td class="desc-text-3" style="vertical-align: top;"> Rp {{ toCurrency(item.paidAmount) }} </td>
            </tr>
          </table>
        </div>
        <a-divider/>
        <div class="d-flex justify-content-between mb-4">
          <div class="text-left mb-2 w-100 mt-3">
            <div class="d-flex">
              <div class="w-50 desc-text-1">Yang Menerima</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="w-50 desc-text-2">{{ distributorDetail && distributorDetail.name || '-' }}</div>
            </div>
            <div class="d-flex">
              <div class="w-50 desc-text-1">Terbayar Dengan Metode Pembayaran</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="w-50 desc-text-2">{{ paymentName }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-breaker" />

    </div>
    <div v-else class="loader-page d-flex justify-content-center align-items-center">
      <a-icon type="loading" class="loader-icon"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'receiptopenauth',
  components: {
  },
  data: () => ({
    orderDetail: null,
    orderList: [],
    receiptDetail: null,
    sellerDetail: null,
    customerDetail: null,
    paymentName: '',
    distributorDetail: null,
    subTotalOrder: 0,
    loading: false,
  }),
  computed: {
    ...mapState(['account', 'storeSetting']),
    ...mapState('user', {
      store_url: state => state.store_url,
    }),
    getOrderDate() {
      const orderDate = this.receiptDetail ? this.receiptDetail.created_at : null
      return orderDate ? moment(orderDate).format('DD MMMM YYYY') : ''
    },
    getFullName() {
      return this.customerDetail ? this.customerDetail.name : ''
    },
  },
  methods: {
    fetchDataApp() {
      this.$store.dispatch('GETDATAADMIN')
        .then((data) => {
          this.getPaymentDetail(data.channel_id)
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPaymentDetail(channel_id) {
      this.loading = true
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (params) {
        this.$store.dispatch('billing/GETDETAILSETTLEMENTPAYMENTHISTORYNOAUTH', {
          payment_id: params.id,
          channel_id: channel_id,
          token: params.token,
        })
          .then(({ data }) => {
            this.receiptDetail = data
            this.paymentName = data?.method?.payment_name ?? '-'
            if (data?.payment_orders?.length > 0) {
              /* eslint-disable no-unused-expressions */
              data?.payment_orders?.map((obj) => {
                this.$store.dispatch('purchase/GETDETAIL', {
                  channel_id: channel_id,
                  order_id: obj.order_id,
                })
                  .then(({ data }) => {
                    this.customerDetail = data.customer_info
                    this.subTotalOrder = this.subTotalOrder + obj.total_order
                    this.orderList.push({
                      orderNumber: obj.order_number,
                      productList: data.items.map((item) => item),
                      address: data.destination_data,
                      orderDate: obj.created_at,
                      totalAmount: obj.total_order,
                      paidAmount: obj.paid_amount,
                      outstandingAmount: obj.outstanding_amount,
                    })
                    this.loading = false
                  })
                  .catch(err => {
                    this.loading = false
                    console.log(err)
                  })
              })
            }
            if (data?.seller_id) {
              const sellerIdDistri = data?.seller_id
              this.$store.dispatch('warehouse/GETWAREHOUSE_SELLER', {
                channel_id: this.$store.state.user.user_data.channel_id,
                seller_id: [sellerIdDistri],
              })
                .then(({ data }) => {
                  this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
                    id: data.map((item) => item.warehouse_id),
                  })
                    .then(({ data }) => {
                      const sellerIdDetail = data.find((obj) => obj.seller_id === sellerIdDistri)
                      this.distributorDetail = sellerIdDetail ?? null
                      this.loading = false
                    })
                    .catch(err => {
                      this.loading = false
                      console.log(err)
                    })
                })
                .catch(err => {
                  this.loading = false
                  console.log(err)
                })
            }
          })
      }
    },
    printLabel () {
      window.print()
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    renderElementTagDiscountPerProduct(obj) {
      if (obj.value_type.toUpperCase() === 'PERCENTAGE') {
        return `${obj.gift_value}%`
      } else {
        return `Rp. ${this.toCurrency(obj.gift_value)}`
      }
    },
    renderElementValueDiscountPerProduct(index, dataListItem) {
      let sellingPrice = dataListItem?.selling_price
      /* eslint-disable no-unused-expressions */
      dataListItem?.promotions?.discounts.map((obj, idx) => {
        if (idx <= index) {
          sellingPrice = sellingPrice - (obj?.promotion_value / dataListItem?.quantity)
        }
      })
      return `Rp. ${this.toCurrency(sellingPrice)}`
    },
    getAddress(value) {
      return value ? `${value.address ? value.address + ',' : ''} ${value.district}, ${value.country}, ${value.postal_code}` : ''
    },
    getOrderDateWithParam(value) {
      return value ? moment(value).format('DD MMMM YYYY') : ''
    },
  },
  mounted () {
    // this.fetchReceiptDetail()
    this.fetchDataApp()
  },
}
</script>

<style lang="scss">

.container-label {
  width: 100%;
  background-color: white;

  .header {
    width: 100%;
    padding: .75rem;
  }

  .page {
    text-align: center;
    margin-top: 2rem;

    .content {
      width: 850px;
      border: 1px solid black;
      // margin-top: 1rem;
      margin: auto;
    }

    .page-breaker {
      // position: relative;
      // text-align: center;
      page-break-before: always;
      // margin-bottom: 20px;
    }
  }

  .btn-primary {
    color: white;
    border: 1px solid #015289;
    background-color: #015CA1;
    border-radius: 8px;
  }
  .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      img {
        width: 100px;
        object-fit: cover;
      }
    }
}

@media print {
  .container-label {
    .header {
      > div {
        display: none;

      }
    }
    .page {
      .content {
        width: 70%;
      }
    }
  }
}

.title-text {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #000000;
}

.desc-text-1 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #767676;
}

.desc-text-2 {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #000000;
}

.desc-text-3 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

.desc-product-discount-text {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  line-height: 16px;
}

.desc-text-blue {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  color: #015CA1;
}

.desc-text-orange {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  color: #F5831F;
}

.price-text-title {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}

.price-text-1 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: #767676;
}

.price-text-2 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

.price-border-bottom {
  line-height: 35px;
  border-bottom: 1px solid #F4F4F4;
}

.invoice-stamp {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  opacity: 0.08;
}

.tr-product-list td {
  padding: 20px 0px;
}

.disc-per-product-tag {
  background-color: #FFF2F2;
  border-radius: 2px;
  padding: 3px;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF0000;
}

.disc-per-product-value {
  padding: 3px;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.text-title {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.text-nomor {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #767676;
}
.text-nomor-2 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #000000;
}
</style>
